<template>
  <div class="modal fade" id="tutor-modal" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xxg" role="document">
      <div class="modal-content container-shadow">
        <div class="modal-header">
          <button id="modal-tutor-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="padding: 0 2.4rem 2.4rem;">
          <div class="row" style="text-align: center; margin: 0;">
            <div class="col-md-6" style="padding: 0;">
              <div style="display: flex; width: 25rem;">
                <div style="margin-right: 2rem;">
                  <div class="section__coaches-carousel-img avatar avatar_sm avatar_small" :style="{backgroundImage: tutor.avatar ? 'url(/pic/tutors/' + tutor.avatar + ')': ''}"></div>
                </div>
                <div style="flex: 2 1 0%;
                  font-size: 1.6rem;
                  padding: 0;
                  line-height: 2rem;
                  margin-top: 1.2rem;" class="section__coaches-carousel-title" v-html="tutor.fullName"></div>
              </div>
            </div>
            <div class="col-md-6 text-left" style="padding: 0; font-size: 1.2rem;">
              <div style="margin-top: 0; font-size: 1.4rem; font-weight: 700">Организация</div>
              <div class="text-left" style="margin: 1rem 0 0;">
                {{ tutor.organization }}
              </div>
            </div>
          </div>
          <div class="row" style="margin: 2.1rem 0 0;">
            <div class="col-md-6" style="padding: 0">
              <div style="margin-top: 0; font-size: 1.4rem; font-weight: 700">Компетенции</div>
              <ul v-html="tutor.qualification"></ul>
            </div>
            <div class="col-md-6" style="padding: 0">
              <div style="margin-top: 0; font-size: 1.4rem; font-weight: 700">Ведет образовательные блоки</div>
              <ul class="blue-ball" v-html="tutor.blocks"></ul>
            </div>
          </div>
        </div>
        <div class="owl-nav" style="justify-content: center; position: absolute; bottom: -7.1rem; width: 100%; zoom: 0.75;">
          <button @click="prev" type="button" style="background: #fff" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>
          <button @click="next" type="button" style="background: #fff" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap'
import {eventBus} from "@/main";
window.jQuery = $
export default {
  name: "ModalTutor",
  props: ['tutor'],
  mounted() {
    $('#tutor-modal').modal('show');
    $('#tutor-modal').on('hide.bs.modal', function () {
      eventBus.$emit('hide:tutor')
    })
  },
  methods: {
    next() {
      eventBus.$emit('tutor:next', this.tutor)
    },
    prev() {
      eventBus.$emit('tutor:next', this.tutor)
    }
  }
}
</script>

<style lang="scss">
#tutor-modal {
  #tooltip {
    cursor: pointer;
    margin-bottom: -15px;
  }
  .comment {
    max-width: 540px;
    flex-basis: 50%;
    margin-left: 50px;
    margin-bottom: 25px;
    padding-left: 53px;
  }
  .comment_bubble {
    padding: 15px;
    max-width: 470px;
  }
  .comment_bubble {
    background: #ebebeb;
    position: relative;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-left: 50px;
  }
  .comment_bubble:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 25px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 13px solid #ebebeb;
    border-bottom: 12px solid transparent;
  }
  #modal-tutor-close {
    display: block;
    top: 2.4rem;
    position: absolute;
    right: 2.4rem;
    z-index: 10000;
  }
  .modal-header {
    padding: 2.4rem 0 0;
  }
  .modal-xxg {
    max-width: 720px;
    zoom: 150%;
    margin-top: -10%;
  }
  .avatar_small {
    width: 5.6rem;
    height: 5.6rem;
    background-size: cover;
  }
  ul {
    list-style: none;
    font-size: 1rem;
    padding: 0;
    li {
      padding: 0.7rem 1rem 0;
      &::before {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: #ED1B2F;
        content: "";
        display: inline-block;
        margin-left: -1rem;
        margin-right: 0.5rem;
      }
    }
    &.blue-ball {
      li {
        &::before {
          background-color: #002E5E;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .modal-body {
      zoom: 160%;
    }
    .modal-xxg {
      margin-top: -50%;
    }
  }
}
</style>