import { render, staticRenderFns } from "./MspForsageAccepted.vue?vue&type=template&id=6d27f150&scoped=true&"
import script from "./MspForsageAccepted.vue?vue&type=script&lang=js&"
export * from "./MspForsageAccepted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d27f150",
  null
  
)

export default component.exports