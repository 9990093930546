let tutors = [
    {
      fullName: 'Светлана Михайловна <br/> Балакирева',
      organization: 'Всероссийская академия внешней торговли при Минэкономразвития России',
        qualification: '<li>Проведение тренингов по вопросам бизнес-психологии деловых переговоров</li>' +
            '<li>Формирование презентационных навыков</li>' +
            '<li>Проведение практикумов по международному протоколу и этикету</li>',
        blocks: '<li>Блок «Подготовка к первым контактам с иностранным контрагентом»</li>' +
            '<li>«Эффективные коммуникации при экспорте»</li>',
        avatar: '1.jpg'
    },
    {
      fullName: 'Анна Владимировна <br/> Беляева',
      organization: 'ПАО «Московский кредитный банк»',
      qualification: '<li>Мировая экономика</li>' +
          '<li>Банковское дело</li>' +
          '<li>Финансы в ВЭД</li>',
      blocks: '<li>Страновой обзор перспективных рынков (Страновой блок)</li>',
        avatar: '2.png'
    },
    {
      fullName: 'Ирина Дмитриевна <br/> Бухарина',
      organization: 'ПАО «Московская Биржа»',
      qualification: '<li>Финансовые рынки</li>' +
          '<li>Хеджирования валютных и ценовых рисков</li>' +
          '<li>Организация торговли на рынке ценных бумаг</li>',
      blocks: '<li>Блок «Валютные риски. Хеджирование»</li>',
        avatar: '3.jpg'
    },
    {
      fullName: 'Олег Георгиевич <br/> Дьяченко',
      organization: 'Всероссийское общество изобретателей и рационализаторов',
      qualification: '<li>Патентно-правовая охрана результатов интеллектуальной деятельности за рубежом</li>',
      blocks: '<li>Блок «Охрана интеллектуальной собственности»</li>',
        avatar: '4.jpeg'
    },
    {
      fullName: 'Мария Андреевна <br/> Исакова',
      organization: 'ООО «Балтийский химический комплекс»',
      qualification: '<li>Правовое сопровождение внешнеэкономической сделки и документооборот</li>' +
          '<li>Логистика и управление цепями поставок</li>' +
          '<li>Управление запасами</li>' +
          '<li>Таможенная логистика</li>',
      blocks: 'Блок «Международная логистика»',
        avatar:  '5.jpeg'
    },
    {
      fullName: 'Андрей Александрович <br/> Кодыков',
      organization: 'Компания «Хенш Развитие Качества» (международная сертификация)',
      qualification: '<li>Международная сертификация систем менеджмента (ISO)</li>' +
          '<li>Международная сертификация продукции (СЕ)</li>' +
          '<li>Вывод продукции на международные рынки</li>' +
          '<li>Обучение в области стратегического и операционного менеджмента, проектное управление</li>',
      blocks: '<li>Блок «Подготовка товара к требованиям рынка, покупателя»</li>',
        avatar: '6.jpg'
    },
    {
      fullName: 'Лопатникова Элина Вячеславовна',
      organization: 'АО «Газпромбанк»',
      qualification: '<li>Валютное регулирование</li>' +
          '<li>Валютный контроль</li>',
      blocks: '<li>Блок «Основные риски нарушения требований валютного законодательства при реализации экспортного проекта»</li>',
        avatar: '7.jpeg'
    },
    {
      fullName: 'Михаил Юрьевич <br/> Меньшиков',
      organization: 'Тренер, наставник, автор-разработчик образовательных программ Школы экспорта РЭЦ',
      qualification: '<li>Экспортный маркетинг в части исследований зарубежного рынка</li>' +
          '<li>Экспортный консалтинг</li>' +
          '<li>Разработка экспортных стратегий</li>' +
          '<li>Business development</li>',
      blocks: '<li>Блок «Выбор зарубежного рынка»</li>' +
          '<li>Блок «Модель выхода на внешний рынок»</li>',
        avatar: '8.png'
    },
    {
      fullName: 'Татьяна Викторовна <br/> Мирумян',
      organization: 'Автор-разработчик образовательных программ Школы экспорта РЭЦ',
      qualification: '<li>Профессиональный банковский опыт всех финансовых продуктов поддержки экспорта, включая инструменты торгового финансирования и экспортного страхования</li>',
      blocks: '<li>Блок «Финансовые инструменты для экспортера»</li>' +
          '<li>Блок «Управление рисками реализации экспортной сделки»</li>',
        avatar: '9.png'
    },
    {
      fullName: 'Борис Александрович <br/> Нейман',
      organization: 'Компания PAL – официальный сервисный партнер торговой площадки Alibaba.com в России',
      qualification: '<li>Интернет-торговля</li>' +
          '<li>Размещение и продвижение на площадке ALIBABA.COM</li>',
      blocks: '<li>Бизнес-кейс «Путь успешного продавца на ALIBABA.com»</li>',
        avatar: '10.png'
    },
    {
      fullName: 'Ольга Владимировна <br/> Одинцова',
      organization: 'Компания CMS International B.V',
      qualification: '<li>Налогообложение</li>' +
          '<li>Юриспруденция</li>' +
          '<li>Бизнес-администрирование</li>',
      blocks: '<li>Блок «НДС при экспорте»</li>',
        avatar: '11.jpeg'
    },
    {
      fullName: 'Елена Владимировна <br/> Попова',
      organization: 'АО «РЖД Логистика»',
      qualification: '<li>Таможенное регулирование</li>' +
          '<li>Логистика ВЭД</li>' +
          '<li>Инкотермс©</li>' +
          '<li>Внешнеторговые сделки</li>',
      blocks: '<li>Блок «Международная логистика»</li>' +
          '<li>Блок «Таможенное оформление экспортной сделки»</li>',
      avatar: '12.jpg'
    },
    {
      fullName: 'Мария Станиславовна <br/> Роскошная',
      organization: 'АО «Русатом Сервис»',
      qualification: '<li>Нераспространение и экспортный контроль</li>' +
          '<li>Внешнеэкономическая деятельность с чувствительной группой товаров и технологий (с продукцией двойного назначения)</li>',
      blocks: '<li>Блок «Экспортный контроль»</li>',
      avatar: '13.png'
    },
    {
      fullName: 'Артем Константинович <br/> Тихонов',
      organization: 'Консалтинговоя компания «Игры и смыслы»',
      qualification: '<li>Разработчик деловых игр и бизнес-симуляций</li>' +
          '<li>Бизнес-тренер по направлению менеджмент, лидерство, ведение переговоров</li>',
      blocks: '<li>Блок «Переговорные поединки»</li>',
      avatar: '14.jpg'
    }
]
export default tutors