<template>
  <div id="acel-tutors-carousel" class="section__coaches-wrapper" style="margin-bottom: 12rem;">
    <div class="title-with-icon mb-2">
      <div class="title-with-icon__icon title-with-icon__icon_coach"></div>
      <div class="title-with-icon__title">Эксперты программы</div>
    </div>
    <div v-if="tutors" class="owl-carousel section__coaches-carousel">
      <a v-for="(tutor, index) in tutors" :key="index" href="javascript:void(0)" @click="openModal(tutor)" class="item">
        <div class="section__coaches-item container-shadow">
          <div class="section__coaches-carousel-img avatar avatar_big" :style="{backgroundImage: tutor.avatar ? 'url(' + '/pic/tutors/' + tutor.avatar + ')': ''}"></div>
          <div class="section__coaches-carousel-title" v-html="tutor.fullName"></div>
          <div class="section__coaches-carousel-region"><span>{{ tutor.organization }}</span></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>

import {eventBus} from "@/main";

export default {
  name: "ExpertTutors",
  data() {
    return {
      tutor: null
    }
  },
  props: ['tutors'],
  mounted() {
    eventBus.$on('tutor:next', (tutor) => {
      let indexTutor = this.tutors.indexOf(tutor)
      tutor = this.tutors[indexTutor + 1];
      if(!tutor) {
        tutor = this.tutors[0]
      }
      eventBus.$emit('open:tutor', tutor)
    })
    eventBus.$on('tutor:prev', (tutor) => {
      let indexTutor = this.tutors.indexOf(tutor)
      tutor = this.tutors[indexTutor - 1];
      if(!tutor) {
        tutor = this.tutors[this.tutors.length - 1]
      }
      eventBus.$emit('open:tutor', tutor)
    })
  },
  methods: {
    openModal(tutor) {
      eventBus.$emit('open:tutor', tutor)
    }
  }
}
</script>

<style lang="scss">
#acel-tutors-carousel {
  @media (max-width: 768px) {
    .section__coaches-carousel-region span {
      padding-left: 15px;
      background: none!important;
      font-size: 12px!important;
    }
    .section__coaches-item {
      height: 300px!important;
    }
    .owl-item {
      width: 200px!important;
    }
  }
}
</style>