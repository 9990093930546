<template>
  <div class="modal fade" id="acelerator-modal" tabindex="-1" role="dialog" aria-labelledby="add-question" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <form @submit.prevent="submit">
        <div v-show="!showNext" class="modal-content container-shadow">
          <div class="modal-header">
            <div class="title-with-icon">
              <div class="title-with-icon__icon title-with-icon__icon_login"></div>
              <div class="title-with-icon__title">
                <span class="title-login active">Ваша организация участник нацпроекта?</span>
              </div>
            </div>
            <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="section__acceleration-description-text">
              <p>Программа Акселератор экспортного роста предназначена исключительно для участников национального проекта «Производительность труда».</p>
              <div style="display: flex;flex-direction: row;"><div style="margin-right: 1rem;">Является ли Ваша организация участником данного нацпроекта?</div> <span id="tooltip"></span></div>
            </div>
          </div>
          <div class="modal-footer" style="margin: 0 -1.5rem">
            <div class="col">
              <a href="/user/register?type=company" class="btn btn-primary"><span>Да, является</span></a>
            </div>
            <div class="col-auto">
              <button type="button" @click="showNext = true" class="btn btn-outline-primary"><span>Нет, не является</span></button>
            </div>
          </div>
        </div>
        <div v-show="showNext" class="modal-content">
          <div class="modal-header">
            <div class="title-with-icon">
              <div class="title-with-icon__icon title-with-icon__icon_login"></div>
              <div class="title-with-icon__title">Станьте участником нацпроекта!</div>
            </div>
            <button id="modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="section__acceleration-description-text">
              <p>Предлагаем Вам рассмотреть возможность стать участником нацпроекта «Производительность труда», который направлен на повышение конкурентоспособности российских товаров и услуг, создание культуры высокой производительности и эффективности на российских предприятиях.</p>
              <p>Необходимо указать минимальные сведения, чтобы сотрудники Федерального центра компетенций могли с Вами связаться.</p>
            </div>
            <div class="form-item">
              <label for="name"><b>Имя и Фамилия</b></label>
              <input v-model="data.name" id="name" class="form-control" required placeholder="Введите Имя и Фамилию" />
            </div>
            <div class="form-item">
              <label for="phone-acel"><b>Мобильный телефон</b></label>
              <input v-model="data.phone" v-imask="{mask: '+ {7} (000) 000 0000',lazy: true}" :class="{'is-invalid': isError}" class="form-control" id="phone-acel" required placeholder="+7 (999) 999 9999" />
              <div class="invalid-feedback">
                Проверьте правильность ввода номера
              </div>
            </div>
            <div class="form-item">
              <label for="inn"><b>ИНН компании</b></label>
              <input v-model="data.inn" v-imask="{mask: '00000000000', lazy: true}" :class="{'is-invalid': isErrorInn}" class="form-control" id="inn" required placeholder="Введите ИНН" />
              <div class="invalid-feedback">
                Проверьте правильность ИНН
              </div>
            </div>
          </div>
          <div class="modal-footer" style="justify-content: flex-end;">
            <button type="submit" class="btn btn-primary"><span>Отправить заявку</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import ApiService from "../../services/api.service"; // optional for styling
window.jQuery = $
import { IMaskDirective } from 'vue-imask';
export default {
  name: "ModalAcel",
  directives: {
    imask: IMaskDirective
  },
  data() {
    return {
      showNext: false,
      data: {},
      isError: false,
      isErrorInn: false
    }
  },
  mounted() {
    $('#acelerator-modal').on('show.bs.modal', function () {
      this.showNext = false
    });
    tippy('#tooltip', {
      content: 'Подтверждением участия в национальном проекте является подписанное соглашение о реализации национального проекта «Производительность труда» с высшим исполнительным органом государственной власти субъекта Российской Федерации',
    });

  },
  methods: {
    submit() {
      if(!this.data.phone || this.data.phone.length < 18) {
        this.isError = true
        return;
      }
      this.isError = false

      if(!this.data.inn || this.data.inn.length < 10) {
        this.isErrorInn = true
        return;
      }
      this.isErrorInn = false

      ApiService.post('acelorder', this.data).then(() => {
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="scss">
#tooltip {
  cursor: pointer;
  display: inline-block;
  background-image: url('/pic/white-question.svg');
  width: 25px;
  height: 25px;
  margin-left: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  position: relative;
  &:hover {
    background-image: url('/pic/white-question-hover.svg');
  }
}
.tippy-box {
  background-color: #fff;
  color: #1C75BC;
  box-shadow: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.08), 0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 0 0.1rem rgba(0, 0, 0, 0.04);
  transition: box-shadow ease-in-out 0.5s;
  border-color: #fff;
  font-weight: 700;
  border-radius: 3rem;
  padding: 1.2rem 1.2rem;
}
.tippy-box > .tippy-arrow:before {
  content: "";
  position: absolute;
  left: 0;
  border-bottom-color: #fff;
}
.form-item {
  margin-top: 1rem;
}
</style>