<template>
  <div class="content_18">
    <h3 class="mb-2 text-danger">Внимание!</h3>
    <p>
      Вы пытаетесь подать заявку на акселерационную программу из действующего личного кабинета.<br/>
      Ваш личный кабинет будет преобразован в кабинет компании.<br/>
      Данные о пройденном обучении по другим программам Школы экспорта РЭЦ будут утеряны.<br/>
    </p>
    <form-error v-if="error" :error="error" />
    <div class="d-flex justify-content-between align-items-center mt-3">
      <button type="button" class="btn btn-md btn-secondary mr-5" data-dismiss="modal">Отмена</button>
      <base-button-send @click.native="accepted()" text="Согласен" />
    </div>
  </div>
</template>

<script>
import BaseButtonSend from "@/components/form/BaseButtonSend.vue";
import {mapGetters} from "vuex";
import ApiService from "../../../services/api.service";
import FormError from "@/components/FormError.vue";
import {TokenService} from "../../../services/token.service";
import $ from "jquery";

export default {
  name: "MspForsageAccepted",
  components: {FormError, BaseButtonSend},
  methods: {
    async accepted() {
      this.error = null
      let res = await ApiService.post('msp-forsage/order/' + this.mspForsageOrderRequest + '/confirm')
      if(res.status === 200) {
        let redirect = this.program === 1 ? '/lk/forsage/company' : '/lk/msp/company'
        let res = await ApiService.get('user')
        await TokenService.setUser(res.data)
        $(`#msp-forsage-${this.program}`).modal('hide');
        await this.$router.push(redirect)
      } else {
        this.error = res.data
      }
    }
  },
  data() {
    return {
      error: null
    }
  },
  props: {
    program: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['mspForsageOrderRequest'])
  }
}
</script>

<style scoped>

</style>