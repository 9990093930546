<template>
  <div class="form-item form-item_groupped" :class="{'form-item_groupped_required': required}">
    <label :for="name"><span v-html="label"></span></label>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "BaseInputWrapper",
  inject: ['$validator'],
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: [Boolean],
      default() {
        return true
      }
    }
  }
}
</script>

<style scoped>

</style>